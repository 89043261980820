#ctxWrap {margin-bottom: 16px; display: flex; flex-wrap: wrap; align-items: stretch; gap: 20px;}
#actWrap {flex-basis: 20%;}
#actWrap .ctx {text-align: center; margin-top: 50px;}
#actWrap .ctx label {font-size: 14px; margin-bottom: 5px; display: block;}
.hints {text-align: left; margin-bottom: 5px;}
.hint-lists {list-style-type: none; margin-top: 5px; padding: 0;}
.hint-lists li {font-size: 0.83em; text-align: left; margin-bottom: 5px;}
#resultWrap {max-height: 600px; overflow-y: scroll;}
#resultWrap h3 {text-align: left; margin-bottom: 5px;}
#resultWrap .resultBtn {font-size: 14px; margin-bottom: 10px; display: none;}
#resultWrap .data-grid {width: 100%;}
#resultWrap .data-grid tr:first-child td {text-align: center;}
#resultWrap .data-grid td {font-size: 12px; text-align: left; padding: 5px}
#resultWrap .data-grid td:first-child {text-align: center; width: 80px;}
#resultWrap .data-grid td:nth-child(3) {width: 100px;}
#resultWrap.max .data-grid-container {max-height: 160px; display: block; overflow: auto;}

@media only screen and (max-width: 1203px) {

}
@media only screen and (max-width: 1024px) {

}
@media only screen and (max-width: 768px) {
  #actWrap {flex-basis: 100%;}
  #actWrap .ctx {margin-top: 0;}
  #resultWrap {max-height: unset;}
  #resultWrap h3 {font-size: 16px;}
  #resultWrap.max .data-grid-container {display: initial; overflow: unset;}
}
@media only screen and (max-width: 575px) {
  
}