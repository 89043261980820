#header-nav-backdrop {
    position: fixed;
    z-index: 80;
    top: 0;
    left: 0;
    background-color: black;
    width: 100vw;
    height: 100vh;
    display: none;
}
#header-nav-backdrop.show {
    opacity: .5;
    display: block;
}

#header-nav-area {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: all 0.25s linear;
    -moz-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear;
    width: 0;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    border-left: 1px solid #f5f5f5;
}
#header-nav-area.show {width: 280px;}
#header-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: block;
}
#header-nav ul li a {color: #212529; display: block;}
#header-nav ul li a:hover {background-color: lightblue;}
#header-nav ul li:last-child a, #header-nav a.mobile-nav {margin-right: 0;}
a.mobile-nav {font-size: 22px; color: gray; display: block;}
a.mobile-nav.cross {text-align: right; margin: 0 15px 15px 8px;}

@media only screen and (max-width: 1203px) {

}
@media only screen and (max-width: 1024px) {

}
@media only screen and (max-width: 768px) {
    
}
@media only screen and (max-width: 575px) {
  a.mobile-nav.nav-btn {
    position: fixed;
    bottom: 180px;
    left: calc(50% - 40px);
    z-index: 88;
    background-color: #3a3a3a;
    color: white;
    text-align: center;
    width: 48px;
    border-radius: 20px;
  }
}