.inputWrapCtx {margin-bottom: 16px; display: flex; flex-wrap: wrap;}
.inputWrapCtx label {
  font-size: 12px;
  vertical-align: top;
  text-align: right;
  height: 48px;
  margin-right: 8px;
  display: block;
  box-sizing: border-box;
}
.inputWrapCtx input {
  font-size: 42px;
  line-height: 46px;
  width: 260px;
  height: 60px;
  display: block;
  border: none;
  box-sizing: border-box;
}
.inputWrapCtx input:focus {outline: none;}
.inputWrapCtx input::placeholder {color: #D5D5D5; opacity: 1;}
.inputWrapCtx input:-ms-input-placeholder {color: #D5D5D5;}
.inputWrapCtx input::-ms-input-placeholder {color: #D5D5D5;}

.borderWrapCtx {
  padding: 5px;
  margin-bottom: 16px;
  display: block;
  border: 1px solid #DDD;
  box-sizing: border-box;
}
.borderWrapCtx.col-1 {flex-basis: 100%;}
.borderWrapCtx.col-2 {flex-basis: 48%;}
.borderWrapCtx.col-2:nth-child(2) {margin-left: 4%;}
.borderWrapCtx.read {background-color: #E0E0E0;}
.borderWrapCtx label {
  font-size: 16px;
  vertical-align: top;
  text-align: left;
  height: 18px;
  display: block;
  box-sizing: border-box;
}
.borderWrapCtx input {
  font-size: 18px;
  line-height: 32px;
  width: 100%;
  height: 32px;
  display: block;
  border: none;
  box-sizing: border-box;
}
.borderWrapCtx input:focus {outline: none;}
.borderWrapCtx input:read-only {background-color: #E0E0E0;}
