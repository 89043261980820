hr {display: none;}

#onelineInOut {display: flex; flex-wrap: wrap;}
#onelineInOut #inputWrap {padding-right: 4%; border-right: 1px solid #D5D5D5; box-sizing: border-box; flex: 46%;}
#onelineInOut #outputWrapFlex {padding-left: 4%; margin-bottom: 0; box-sizing: border-box; flex: 46%;}

#inputWrap {display: flex; flex-wrap: wrap;}
#inputBtn {font-size: 16px; width: 100px; height: 36px; margin: 0 0 20px 60px;}
#outputWrapFlex {margin-bottom: 16px; display: flex; flex-wrap: wrap;}
#outputWrap {margin-bottom: 16px;}
#descWrap {margin-top: 32px;}
#descWrap p {font-size: 14px;}
#descWrap ul {font-size: 14px; padding: 0;}
#descWrap ul ol {padding-left: 16px; display: flex;}
#descWrap ul ol div:first-child {white-space: nowrap; width: 43px !important;}
#descWrap ul ol div ul li {margin-left: 16px;}

.featureWrap {margin-bottom: 25px;}
.featureWrap .featureList {color: #606060; width: 100%;}
.featureWrap .featureList th, .featureWrap .featureList td {text-align: center; padding: 8px 0; border-bottom: 1px solid #d8d7cb}
.featureWrap .featureList th:first-child, .featureWrap .featureList td:first-child {border-right: 1px solid #d8d7cb}
.featureWrap .featureList th {background-color: #CCC;}
.featureWrap .featureList td.night {background-color: #4572a7; color: white;}
.featureWrap .featureList td.earlyMorning {background-color: #ecfafa;}
.featureWrap .featureList td.morning {background-color: lightyellow;}
.featureWrap .featureList td.afternoon {background-color: #FFC55C; color: #303030;}
.flexContainer {display: flex; flex-wrap: wrap; justify-content: space-between;}
.flexContainer .flexItem {
	color: #727cf5;
	text-align: center;
	text-decoration: none;
	padding: 8px 10px;
	margin-bottom: 10px;
	border: 1px solid #727cf5;
	border-radius: 5px;
	box-sizing: border-box;
	flex-basis: 30%;
}

@media only screen and (max-width: 1203px) {

}
@media only screen and (max-width: 1024px) {
	hr {margin: 30px 0; display: block;}
	#onelineInOut {display: block;}
	#onelineInOut #inputWrap {padding-right: 0; border-right: none;}
	#onelineInOut #outputWrapFlex {padding-left: 0;}
}
@media only screen and (max-width: 768px) {
  .flexContainer .flexItem {flex-basis: 43%;}
}
@media only screen and (max-width: 575px) {
  
}