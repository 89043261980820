.areaField {flex-basis: calc(40% - 20px);}
.areaField h3 {text-align: center !important; margin-bottom: 5px;}
.areaField textarea {width: 100%; height: 160px; resize: none; box-sizing: border-box;}


@media only screen and (max-width: 1203px) {

}
@media only screen and (max-width: 1024px) {

}
@media only screen and (max-width: 768px) {
  .areaField {flex-basis: 100%;}
  .areaField h3 {font-size: 16px;}
}
@media only screen and (max-width: 575px) {
  
}