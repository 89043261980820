body {
  margin: 0;
  font-family: 'Helvetica', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  touch-action: pan-x pan-y;
  height: 100% 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.textLink {
  color:inherit;
  text-decoration:none;
  cursor:text;
}

.left-side-banner, .right-side-banner {
  position: fixed;
  top: 60px;
  vertical-align: top;
  width: 160px;
  height: 600px;
  min-width: 120px;
  min-height: 125px;
  display: block;
}
.left-side-banner {text-align: right;}
.left div {vertical-align: top; text-align: left;}
.right-side-banner {text-align: left;}
.right-side-banner div {vertical-align: top; text-align: left;}

.footer-anchor {
  position: fixed !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  text-align: center;
  height: 100px;
}
.footer-anchor div {
  text-align: center;
  height: 100px !important;
  margin: 0 auto;
}


@media only screen and (min-width: 960px) and (max-width: 1343px) {
  .right-side-banner {
    right: 0;
    text-align: right;
    width: 192px;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .right-side-banner div {text-align: right;}
  .left-side-banner {display: none;}
}
@media only screen and (min-width: 1025px) and (max-width: 1343px) {
  .right-side-banner {right: calc(50vw - 512px);}
}
@media only screen and (min-width: 1344px) {
  .left-side-banner {z-index: 68; left: 10px;}
  .right-side-banner {z-index: 68; right: 10px;}
}
@media only screen and (max-width: 959px) {
  .left-side-banner {display: none;}
  .right-side-banner {display: none;}
}
@media only screen and (max-width: 575px) {
  body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
}