.outputTab {margin-bottom: 16px; display: flex;}
.outputTab label {
  font-size: 12px;
  vertical-align: top;
  text-align: right;
  height: 48px;
  margin-right: 8px;
  display: block;
  box-sizing: border-box;
}
.outputTab textarea {
  background-color: #EAEAEA;
  font-size: 22px;
  width: calc(100% - 60px);
  display: block;
  border: none;
  box-sizing: border-box;
}