.selectWrapCtx {
  position: relative;
  padding: 5px;
  display: inline-block;
  border: 1px solid #DDD;
  box-sizing: border-box;
}
.selectWrapCtx.col-1 {flex-basis: 100%;}
.selectWrapCtx label {
  font-size: 16px;
  vertical-align: top;
  text-align: left;
  height: 18px;
  display: block;
  box-sizing: border-box;
}
.selectWrapCtx span.btn {
  background-color: white;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 18px;
  color: #696969;
  line-height: 32px;
  text-align: left;
  width: 100%;
  height: 32px;
  padding: 1px 6px;
  display: block;
  border: none;
  box-sizing: border-box;
}
.selectWrapCtx .dropdown-content {
  position: absolute;
  z-index: 88;
  background-color: #f6f6f6;
  min-width: 230px;
  padding: 10px;
  display: none;
  border: 1px solid #ddd;
}
.selectWrapCtx .dropdown-content.show {display: block;}
.selectWrapCtx .dropdown-content input {
  width: 100%;
  height: 36px;
  padding: 5px;
  border: 1px solid #007EE5;
  box-sizing: border-box;
}
.selectWrapCtx .dropdown-content div {max-height: 130px; overflow: auto;}
.selectWrapCtx .dropdown-content div a {
  color: black;
  text-decoration: none;
  padding: 12px 16px;
  display: block;
}

.selectWrapCtx .select-backdrop {
  position: fixed;
  z-index: 80;
  top: 0;
  left: 0;
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: none;
}
.selectWrapCtx .select-backdrop.show {opacity: 0; display: block;}