section {max-width: 1024px; margin: 0 auto;}
header, footer {position: fixed; right: 0; left: 0;}

/*===== Header Part =====*/
header {
  z-index: 70;
  background-color: white;
  border-bottom: 2px solid #f5f5f5;
}
header section {
  padding: 4px 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
header section a {
  text-decoration: none;
  line-height: 43px;
  height: 43px;
  padding: 0 8px;
  margin: 0 8px;
}
header section a.logo {margin-left: 0;}
header section a.logo img {
  width: 91px;
  height: 30px;
  margin-top: 8px;
}

/*===== Main Part =====*/
main section {
  min-height: calc(100vh - 251px);
  padding: 68px 16px 230px;
  box-sizing: border-box;
}

/*===== Footer Part =====*/
footer {
  z-index: 60;
  bottom: 0;
  background-color: #3a3a3a;
  height: 200px;
  box-sizing: border-box;
}
footer section {
  font-size: 14px;
  color: white;
  padding: 32px 16px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
footer section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}
footer section a {
  font-size: 14px;
  color: white;
  text-decoration: none;
}

@media only screen and (min-width: 960px) and (max-width: 1343px) {
  main section {padding-right: 192px}
}
@media only screen and (max-width: 768px) {
  main section h1, main section h3 {font-size: 16px;}
}
@media only screen and (max-width: 575px) {
  footer section, footer section a {font-size: 12px;}
  footer section ul {gap: 10px;}
}