main section h1, main section h3 {margin-top: 0;}
main section h3, main section h4, main section h3 a, main section p.desc {color: #606060;}
main section h3#welcome {font-size: 16px;}
main section h3 a {text-decoration: none;}
main section p.desc {font-weight: 400;}
main section code {font-size: 14px;}
main section p.desc code {font-weight: 900;}

.toolLists {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.toolLists a {
  color: blueviolet;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  width: 298px;
  height: 48px;
  display: block;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  box-sizing: border-box;
}
.toolLists a:hover {
  background-color: rgba(138, 43, 226, 0.2);
}


.codeWrap {
  background-color: rgba(0, 0, 0, .05);
  margin-bottom: 16px;
  border-radius: 5px;
  overflow: hidden;
}
.codeWrap .title {
  background-color: rgba(138, 138, 138, .7);
  color: #fff;
  padding: 8px 16px;
}
.codeWrap .ctx {
  padding: 16px;
}

.code-met, .code-comment {color: #888;}
.code-keyword, .code-var {color: #1E90FF;}
.code-tag {color: #333;}
.code-next {margin-bottom: 8px; display: block;}
.code-attr {color: #df3079;}
.code-string {color: #00a67d;}

@media only screen and (max-width: 1203px) {

}
@media only screen and (max-width: 1024px) {

}
@media only screen and (max-width: 768px) {
  .toolLists a {flex-basis: 100%;}
}
@media only screen and (max-width: 575px) {
  
}